<template>
  <div class="pageWarpper">
    <van-nav-bar title="收银台" border fixed safe-area-inset-top />
    <div class="container">
      <div class="countdonw">
        支付剩余时间：<van-count-down auto-start :time="1000 * 60 * 30" />
      </div>
      <div class="amount">
        <span class="txt">待支付金额（元）</span>
        <strong class="num">1,000.00</strong>
      </div>
      <div class="paymentMethods">
        <van-cell-group inset>
          <van-radio-group v-model="paymentMethodsValue">
            <van-cell
              v-for="item in paymentMethodsList"
              :key="item.value"
              :title="item.label"
              :icon="item.icon"
            >
              <template #right-icon>
                <van-radio :name="item.value" checked-color="#FF6011" />
              </template>
            </van-cell>
          </van-radio-group>
        </van-cell-group>
      </div>
    </div>

    <div class="footer van-hairline--top" safe-area-inset-footer>
      <van-button type="primary" color="#FF9500" @click="handlePay()">
        去支付
      </van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "Cashier",
  data: () => {
    return {
      paymentMethodsValue: "wechat",
      paymentMethodsList: [
        {
          label: "微信",
          value: "wechat",
          icon: "wechat-pay",
        },
        {
          label: "支付宝",
          value: "alipay",
          icon: "alipay",
        },
      ],
    };
  },
  methods: {
    handlePay() {
      this.$router.push("/cashier/success");
    },
  },
};
</script>
<style lang="scss" scoped>
.pageWarpper {
  background: #f8f8f8;
  padding-top: 88px;
  font-family: PingFangSC-Regular, PingFang SC;
  height: calc(100vh - 88px);
}
.container {
  .countdonw {
    font-size: 16px;
    font-weight: 400;
    color: #ff6011;
    line-height: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .amount {
    margin-bottom: 30px;
    .txt {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 30px;
    }
    .num {
      display: block;
      font-size: 36px;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      color: #333333;
      line-height: 42px;
    }
  }

  .paymentMethods {
    .van-cell__title {
      text-align: left;
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 100%;
  padding: 10px 0;
  .van-button {
    width: 90%;
  }
}
</style>